<template>
    <div>
        <v-container>
            <!-- 消息条 -->
            <v-snackbar :timeout="1500" class="mt-4" v-model="notiBar.enabled" top transition="slide-y-transition"
                :color="notiBar.color" center text style="top: 56px">
                {{ notiBar.text }}
            </v-snackbar>

            <!-- 客户端列表区域 -->
            <v-card class="my-3">
                <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h6 pl-0"><v-btn depressed to="/"
                            class="mr-5"><v-icon>mdi-arrow-left-thick</v-icon>返回</v-btn>注册码列表
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="refresh" class="mx-2"><v-icon>mdi-refresh</v-icon></v-btn>
                    <v-btn depressed @click="createCode" color="primary" class="mx-2"><v-icon>mdi-plus</v-icon></v-btn>
                </v-app-bar>
            </v-card>

            <v-card class="mx-auto pa-5 mt-5">
                <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h6 pl-0">注册码列表
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="搜索" single-line
                        hide-details></v-text-field>
                </v-app-bar>

                <!-- 表格区 -->
                <v-card-text>
                    <v-data-table :headers="headers" :items="list" :search="search" :items-per-page="100" :loading="loading"
                        loading-text="正在获取数据。。。" :calculate-widths="false">
                        <!-- active的展示 -->
                        <template v-slot:item.active="{ item }">
                            <v-chip :color="getStatusColor(item.active)" dark small>
                                {{ getStatusText(item.active) }}
                            </v-chip>
                        </template>
                        <!-- active的展示 -->

                        <!-- code的展示 -->
                        <template v-slot:item.code="{ item }">
                            <p class="font-weight-black my-auto">
                                {{ item.code }}
                            </p>
                        </template>
                        <!-- code的展示 -->



                        <!-- exptime的展示 -->
                        <template v-slot:item.expire_time="{ item }">
                            {{ showDateTime(item.expire_time) }}
                        </template>
                        <!-- exptime的展示 -->

                        <!-- 群组的展示 -->
                        <template v-slot:item.user_group="{ item }">
                            {{ show_group_name(item.user_group) }}
                        </template>
                        <!-- 群组的展示 -->

                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="editCode(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon small @click="deleteCode(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                </v-card-text>
            </v-card>

            <!-- 编辑框 -->
            <v-dialog v-model="editor.dialog_open" persistent>
                <v-card>
                    <v-card-title>{{
                        editor.mode == "edit" ? "修改注册码信息" : "新建注册码"
                    }}</v-card-title>
                    <v-card-text>
                        <!-- 编辑框主体部分 -->
                        <v-row>
                            <v-col>
                                <v-select v-model="editor.ownby_select" :items="group_list" item-text="name" item-value="id"
                                    label="用户群组" persistent-hint return-object outlined></v-select>
                            </v-col>
                            <v-col><v-text-field label="注册码" outlined v-model="editor.data.code"></v-text-field></v-col>
                            <v-col><v-switch v-model="editor.data.active" inset label="是否启用"></v-switch></v-col>
                        </v-row>
                        <v-row>
                            <v-col><v-textarea label="欢迎文本" outlined
                                    v-model="editor.data.welcome_text"></v-textarea></v-col>
                        </v-row>
                        <v-row>
                            <v-col><v-text-field label="已获得" outlined v-model="editor.data.got"></v-text-field></v-col>
                            <v-col><v-text-field label="总数" outlined v-model="editor.data.total"></v-text-field></v-col>
                            <v-col>
                                <!-- 日期选择器 -->
                                <v-menu ref="menu" v-model="editor.date_menu" :close-on-content-click="false"
                                    :return-value.sync="editor.date" transition="scale-transition" offset-y
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="editor.date" label="过期日期" prepend-icon="mdi-calendar"
                                            readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                                    </template>
                                    <v-date-picker v-model="editor.date" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="editor.date_menu = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.menu.save(editor.date)">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                                <!-- 日期选择器 -->
                            </v-col>
                            <v-col>
                                <!-- 时间选择器 -->
                                <v-menu ref="menu2" v-model="editor.time_menu" :close-on-content-click="false"
                                    :nudge-right="40" :return-value.sync="editor.time" transition="scale-transition"
                                    offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="editor.time" label="过期时间"
                                            prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
                                            outlined></v-text-field>
                                    </template>
                                    <v-time-picker v-if="editor.time_menu" v-model="editor.time" full-width
                                        @click:minute="$refs.menu2.save(editor.time)"></v-time-picker>
                                </v-menu>
                                <!-- 时间选择器 -->
                            </v-col>
                        </v-row>
                        <!-- 编辑框主体部分 -->
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary darken-1" text @click="submit">
                            {{ editor.mode == "edit" ? "提交修改" : "创建" }}
                        </v-btn>
                        <v-btn color="red darken-1" text @click="editor.dialog_open = false">
                            关闭
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <!-- 编辑框 -->

            <!-- 提示框 -->
            <v-dialog v-model="alert.alert" max-width="450px">
                <v-card>
                    <v-card-title>{{ alert.alert_type }}</v-card-title>
                    <v-card-text>
                        {{ alert.alert_text }}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="alert.alert = !alert.alert">OK</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- 删除提示框 -->
            <v-dialog v-model="deleteData.dialog" max-width="450px">
                <v-card>
                    <v-card-title>确认删除</v-card-title>
                    <v-card-text> 请再次确认？ </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="doDelete">删除</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteData.dialog = false">取消</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>
  
<script>
export default {
    name: "adminRegcode",
    data: function () {
        return {
            search: "",
            headers: [
                { text: "状态", value: "active" },
                { text: "注册码", value: "code" },
                { text: "用户群组", value: "user_group" },
                { text: "过期时间", value: "expire_time" },
                { text: '操作', value: 'actions', sortable: false },
            ],
            list: [],
            group_list: [],
            group_name_map: null,
            editor: {
                mode: "",
                dialog_open: false,
                data: {
                    id: 6,
                    active: false,
                    code: "testcode",
                    user_group: 0,
                    created_by: 0,
                    expire_time: "2024-12-31 13:38:27",
                    got: 3,
                    total: 10,
                    welcome_text: "test-text"
                },
                date_menu: false,
                date: "",
                time_menu: false,
                time: "",
                ownby_select: {},
            },
            loading: false,
            alert: {
                alert_text: "",
                alert_type: "info",
                alert: false,
            },
            notiBar: {
                enabled: false,
                color: "",
                text: "",
            },
            deleteData: {
                dialog: false,
                id: 0,
            },
        };
    },
    mounted: function () {

        var _this = this;
        //获取群组信息
        this.axios
            .get("/api/admin/listUserGroup")
            .then((res) => {
                if (res.data.status === 0) {
                    this.group_list = res.data.data.list;
                    this.editor.ownby_select = this.group_list[0];

                    this.group_name_map = new Map();
                    for (var group of _this.group_list) {
                        _this.group_name_map.set(group.id, group.name);
                    }

                } else {
                    this.errorBar(this.parseError(res));
                }
                return;
            })
            .catch((err) => {
                (err);
                this.error("发生错误:" + err);
            });
        this.initData();
    },
    watch: {

    },
    beforeDestroy() {
    },
    methods: {
        initData: function () {
            this.loading = true;
            //获取数据
            this.axios
                .get("/api/admin/listRegCode")
                .then((res) => {
                    if (res.data.status === 0) {
                        // console.log(res.data.data.stats);
                        this.list = res.data.data.list;
                    } else {
                        this.errorBar(this.parseError(res));
                    }
                    this.loading = false;
                    return;
                })
                .catch((err) => {
                    (err);
                    this.error("发生错误:" + err);
                });
        },
        submit: function () {
            this.editor.data.expire_time = this.editor.date + "T" + this.editor.time + ":00+08:00";
            this.editor.data.got = Number(this.editor.data.got);
            this.editor.data.total = Number(this.editor.data.total);
            this.editor.data.user_group = String(this.editor.ownby_select.id);
            //发送
            this.axios({
                method: "post",
                url: "/api/admin/editRegCode",
                data: {
                    edit_type: this.editor.mode,
                    data: this.editor.data
                },
            })
                .then((res) => {
                    if (res.data.status === 0) {
                        this.editor.dialog_open = false;
                        this.successBar("操作成功");
                        this.initData();
                    } else {
                        this.error(this.parseError(res));
                    }
                    return;
                })
                .catch((err) => {
                    (err);
                    this.error("发生错误:" + err);
                });
        },
        createCode: function () {
            this.editor.mode = "add";
            this.editor.data = {}
            this.editor.data.got = "0";
            this.editor.data.total = "50";
            this.editor.data.active = true;
            this.editor.data.welcome_text = "感谢注册成为「xxx」管理员，管理入口：https://xxxxx"
            this.editor.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)
            this.editor.time = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(11, 16)
            this.editor.dialog_open = true;
        },
        editCode: function (item) {
            ("editCode", item);
            this.editor.mode = "edit";
            this.editor.data = this.copy(item);
            this.editor.date = this.editor.data.expire_time.split("T")[0]
            this.editor.time = this.editor.data.expire_time.split("T")[1].substring(0, 5)
            this.editor.dialog_open = true;
        },
        deleteCode: function (item) {
            this.deleteData.dialog = true;
            this.deleteData.id = item.id;
        },
        doDelete: function () {
            this.axios({
                method: "post",
                url: "/api/admin/delRegCode",
                data: {
                    id: this.deleteData.id
                },
            })
                .then((res) => {
                    if (res.data.status === 0) {
                        this.successBar("删除成功");
                        this.initData();
                    } else {
                        this.error(this.parseError(res));
                    }
                    this.deleteData.dialog = false;
                    return;
                })
                .catch((err) => {
                    console.log(err);
                    this.error("发生错误:" + err);
                });
        },
        getStatusColor: function (status) {
            if (status) {
                return "green";
            } else {
                return "grey";
            }
        },
        getStatusText: function (status) {
            if (status) {
                return "已启用";
            } else {
                return "停用";
            }
        },
        show_group_name: function (data) {
            if (data == 0) {
                return "系统"
            }
            var out = this.group_name_map.get(Number(data));
            if (out == null) {
                return "NA"
            }
            return ["【", data, "】", out].join("");
        },
        showDateTime: function (datetime) {
            return String(datetime).split("T")[0] + " " + String(datetime).split("T")[1].substring(0, 5) + ":00";
        },
        randem_num() {
            return Math.round(Math.random() * 999);
        },
        copy: function (data) {
            var objString = JSON.stringify(data);
            return JSON.parse(objString);
        },
        refresh: function () {
            this.initData();
            this.successBar("刷新成功");
        },
        success: function (text) {
            this.alert.alert = !this.alert.alert;
            this.alert.alert_text = text;
            this.alert.alert_type = "成功";
        },
        error: function (err) {
            this.alert.alert = !this.alert.alert;
            this.alert.alert_text = err;
            this.alert.alert_type = "错误";
        },
        successBar: function (text) {
            this.notiBar.enabled = !this.notiBar.enabled;
            this.notiBar.text = text;
            this.notiBar.color = "success";
        },
        errorBar: function (err) {
            this.notiBar.enabled = !this.notiBar.enabled;
            this.notiBar.text = err;
            this.notiBar.color = "error";
        },
    },
};
</script>
  
<style></style>
  